.draggable-block-menu {
  border-radius: 4px;
  width: 18px;
  height: 25px;
  cursor: grab;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.draggable-block-menu:active {
  cursor: grabbing;
}

.draggable-block-menu:hover {
  background-color: var(--gray2);
}

.draggable-block-target-line {
  pointer-events: none;
  background: var(--blue7);
  height: 4px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  will-change: transform;
}
