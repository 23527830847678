.link-editor {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 360px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: var(--boxShadow2);
  border-radius: 4px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

.link-editor .link-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
}

.link-editor .link-input {
  display: block;
  box-sizing: border-box;
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: var(--gray18);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
  padding: 0;
  flex: 1;
}

.link-editor .link-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 1.3rem;
  line-height: 1.8rem;
  border: 0;
  outline: 0;
  color: var(--gray18);
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.link-editor div.link-edit {
  cursor: pointer;
}

.link-editor div.link-edit:hover {
  text-decoration: underline;
}

.link-editor .link-trash-container {
  padding: 8px 0;
  border-top: 1px solid var(--gray3);
}

.link-editor div.link-trash:hover {
  background-color: var(--gray3);
}

.link-editor div.link-trash:active {
  background-color: var(--gray2);
}

.link-editor div.link-trash {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.link-editor div.link-confirm {
  cursor: pointer;
}

.link-editor div.link-confirm:hover {
  text-decoration: underline;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}
